import {
  Module,
  VuexModule,
  MutationAction,
  getModule,
  Action,
  Mutation
} from "vuex-module-decorators";
import store from "@/store";
import API from "@/store/API/DemandAndSupply";
import {
  Supply,
  Demand,
  SeedType,
  Preferences,
  Supplier,
  MarketStat,
  SupplierStat,
  SupplierInfo,
  SupplierInfoPaginated,
  SupplierDetail,
  NoticeList,
  ClusterPaginated,
  PaginatedSupply,
  PaginatedDemand,
  Distribution,
  PaginatedDistribution,
  PaginatedUnsoldViewDetail,
  Purchase,
  PaginatedPurchase,
  Sale,
  PaginatedSale,
  SubsidyBatch,
  SubsidySchedule,
  SubsidyReport,
  MyAllocation,
  PaginatedMyAllocation,
  AdminTotalDemandQuantityAPI,
  AdminTotalSupplyQuantityAPI
} from "@/store/models/DemandAndSupply";
import { SeedClass, SubsidyDocument } from "@/store/models/user";
import { CropVarietyPaginated } from "@/store/models/cropVariety";
import { SubsidyForm, SubsidyNotice } from "@/store/models/crop";

@Module({ name: "DemandSupplyStore", dynamic: true, store })
class DemandSupplyModule extends VuexModule {
  public SeedTypeList: SeedType[] = [] as SeedType[];
  public SeedClassList: SeedClass[] = [] as SeedClass[];
  public Demands: Demand[] = [] as Demand[];
  public PaginatedDemand: PaginatedDemand = {} as PaginatedDemand;
  public DemandCluster: ClusterPaginated = {} as ClusterPaginated;
  public Supplies: Supply[] = [] as Supply[];
  public Supply: Supply = {} as Supply;
  public PaginatedSupply: PaginatedSupply = {} as PaginatedSupply;
  public PaginatedApproveAllocation: PaginatedSupply = {} as PaginatedSupply;
  public SupplyCluster: ClusterPaginated = {} as ClusterPaginated;
  public Suppliers: Supplier[] = [] as Supplier[];
  public Preferences: Preferences[] = [] as Preferences[];
  public Subsidy: SubsidyForm[] = [] as SubsidyForm[];
  public MarketStat: MarketStat = {} as MarketStat;
  public SupplierStat: SupplierStat = {} as SupplierStat;
  public SupplierInfo: SupplierInfoPaginated = {} as SupplierInfoPaginated;
  public SupplierDetail: SupplierDetail = {} as SupplierDetail;
  public Notices: NoticeList = {} as NoticeList;
  public UnsoldDetailsPaginated: PaginatedDistribution = {} as PaginatedDistribution;
  public UnsoldViewDetail: PaginatedUnsoldViewDetail = {} as PaginatedUnsoldViewDetail;
  public UnsoldDemandDetail: Distribution = {} as Distribution;

  public Purchases: Purchase[] = [] as Purchase[];
  public PaginatedPurchase: PaginatedPurchase = {} as PaginatedPurchase;
  public PurchaseCluster: ClusterPaginated = {} as ClusterPaginated;

  public Sales: Sale[] = [] as Sale[];
  public PaginatedSale: PaginatedSale = {} as PaginatedSale;
  public SaleCluster: ClusterPaginated = {} as ClusterPaginated;

  public SubsidyCrops: SubsidyForm[] = [] as SubsidyForm[];

  public FyWiseSubsidyCrops: SubsidyForm[] = [] as SubsidyForm[];
  public FyWiseSubsidy: SubsidyForm[] = [] as SubsidyForm[];

  SubsidyNotice: SubsidyNotice = {} as SubsidyNotice;

  CurrentSubsidyBatch: SubsidyBatch = {} as SubsidyBatch;
  CurrentSubsidyDocument: SubsidyDocument = {} as SubsidyDocument;
  SubsidySchedule: SubsidySchedule = {} as SubsidySchedule;
  SourceSeedSubsidySchedule: SubsidySchedule = {} as SubsidySchedule;
  ImprovedSeedSubsidySchedule: SubsidySchedule = {} as SubsidySchedule;

  public MyAllocation: PaginatedMyAllocation = {} as PaginatedMyAllocation;
  public TotalSupplyQuantity: AdminTotalSupplyQuantityAPI = {} as AdminTotalSupplyQuantityAPI;
  public TotalDemandQuantity: AdminTotalDemandQuantityAPI = {} as AdminTotalDemandQuantityAPI;

  @MutationAction
  async getAllSeedType(): Promise<any> {
    let seedTypeList = await API.getAllSeedType();
    return { SeedTypeList: seedTypeList as SeedType };
  }

  @MutationAction
  async getAdminDemands(params: {
    page?: number;
    fiscalYearId?: number;
    cropId?: number;
    seedTypeId?: number;
    cropVarietyId?: number;
    status?: string;
    searchCompany?: string;
    searchCode?: string;
    lang?: string;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropId) {
        query += "&croptypeid=" + String(params.cropId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.status) {
        query += "&status=" + String(params.status);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
      if (params.searchCompany) {
        query += "&companyname=" + String(params.searchCompany);
      }
      if (params.lang) {
        query += "&lang=" + String(params.lang);
      }
    }
    let demandList = await API.getAdminDemands(query);
    return { PaginatedDemand: demandList as PaginatedDemand };
  }

  @MutationAction
  async getAllDemands(
    params: { lang: string; crop?: number; subsidyBatch?: number } = { lang: "en" }
  ): Promise<any> {
    let query = `?lang=${params.lang}`;
    if (params.crop) {
      query += `&cropvarietyid__croptypeid=${params.crop}`;
    }
    if (params.subsidyBatch) {
      query += `&subsidy_batch=${params.subsidyBatch}`;
    }
    let demandList = await API.getAllDemands(query);
    return { Demands: demandList as Demand[] };
  }

  @MutationAction
  async getAllDemandCluster(
    params: {
      lang: string;
      page?: number;
      fiscalYearId?: number;
      searchCode?: string;
      type?: any;
    } = {
      lang: "en",
      page: 1
    }
  ) {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.type) {
        query += "&type=" + String(params.type);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
      if (params.lang) {
        query += "&lang=" + params.lang;
      }
    }
    let demandList = await API.getAllDemandCluster(query);
    return { DemandCluster: demandList as ClusterPaginated };
  }

  @MutationAction
  async getDemandByClusterId(params: { clusterId: number; lang?: string }): Promise<any> {
    let query = ``;
    if (params.lang) {
      query += `?lang=${params.lang}`;
    }
    let demandList = await API.getDemandSupplyByClusterId(params.clusterId, query);
    return { Demands: demandList as Demand[] };
  }

  @Action
  async postDemand(demand: any): Promise<any> {
    return await API.postDemand(demand);
    // return { SeedTypeList: seedTypeList as SeedType };
  }

  @Action
  async deleteDemand(id: number): Promise<any> {
    return await API.deleteDemand(id);
  }

  @Action
  async confirmDemands(ids: number[]) {
    await API.confirmDemands(ids);
  }

  @MutationAction
  async getAllSupplies(
    params: { lang: string; crop?: number; subsidyBatch?: number } = { lang: "en" }
  ): Promise<any> {
    let query = `?lang=${params.lang}`;
    if (params.crop) {
      query += `&cropvarietyid__croptypeid=${params.crop}`;
    }
    if (params.subsidyBatch) {
      query += `&subsidy_batch=${params.subsidyBatch}`;
    }
    let supplyList = await API.getAllSupplies(query);
    return { Supplies: supplyList as Supply[] };
  }

  @MutationAction
  async getAdminSupplies(params: {
    page?: number;
    fiscalYearId?: number;
    cropId?: number;
    seedTypeId?: number;
    cropVarietyId?: number;
    districtId?: number;
    status?: string;
    subsidyStatus?: string;
    searchCompany?: string;
    searchCode?: string;
    lang?: string;
    salesDetail?: boolean;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropId) {
        query += "&croptypeid=" + String(params.cropId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.districtId) {
        query += "&districtid=" + String(params.districtId);
      }
      if (params.status) {
        query += "&status=" + String(params.status);
      }
      if (params.subsidyStatus) {
        query += "&subsidy_status=" + String(params.subsidyStatus);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
      if (params.searchCompany) {
        query += "&companyname=" + String(params.searchCompany);
      }
      if (params.lang) {
        query += "&lang=" + String(params.lang);
      }
      if (params.salesDetail) {
        query += "&sales_detail=" + String(params.salesDetail);
      }
    }

    let supplyList = await API.getAdminSupplies(query);
    return { PaginatedSupply: supplyList as PaginatedSupply };
  }

  @MutationAction
  async getAdminSuppliesTotalQuantity(params: {
    page?: number;
    fiscalYearId?: number;
    cropId?: number;
    seedTypeId?: number;
    cropVarietyId?: number;
    districtId?: number;
    status?: string;
    searchCompany?: string;
    searchCode?: string;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropId) {
        query += "&croptypeid=" + String(params.cropId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.districtId) {
        query += "&districtid=" + String(params.districtId);
      }
      if (params.status) {
        query += "&status=" + String(params.status);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
      if (params.searchCompany) {
        query += "&companyname=" + String(params.searchCompany);
      }
    }
    let TotalSupplyQuantity = await API.getAdminSuppliesTotalQuantity(query);
    return { TotalSupplyQuantity: TotalSupplyQuantity as AdminTotalSupplyQuantityAPI };
  }

  @MutationAction
  async getAdminDemandsTotalQuantity(params: {
    page?: number;
    fiscalYearId?: number;
    cropId?: number;
    seedTypeId?: number;
    cropVarietyId?: number;
    status?: string;
    searchCompany?: string;
    searchCode?: string;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropId) {
        query += "&croptypeid=" + String(params.cropId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.status) {
        query += "&status=" + String(params.status);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
      if (params.searchCompany) {
        query += "&companyname=" + String(params.searchCompany);
      }
    }
    let TotalDemandQuantity = await API.getAdminDemandsTotalQuantity(query);
    return { TotalDemandQuantity: TotalDemandQuantity as AdminTotalDemandQuantityAPI };
  }

  @MutationAction
  async getApproveAllocations(params: {
    page?: number;
    fiscalYearId?: number;
    cropId?: number;
    seedTypeId?: number;
    cropVarietyId?: number;
    districtId?: number;
    status?: string;
    subsidyStatus?: string;
    searchCompany?: string;
    searchCode?: string;
    lang?: string;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropId) {
        query += "&croptypeid=" + String(params.cropId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.districtId) {
        query += "&districtid=" + String(params.districtId);
      }
      if (params.status) {
        query += "&status=" + String(params.status);
      }
      if (params.subsidyStatus) {
        query += "&subsidy_status=" + String(params.subsidyStatus);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
      if (params.searchCompany) {
        query += "&companyname=" + String(params.searchCompany);
      }
      if (params.lang) {
        query += "&lang=" + String(params.lang);
      }
    }

    let supplyList = await API.getApproveAllocation(query);
    return { PaginatedApproveAllocation: supplyList as PaginatedSupply };
  }

  @MutationAction
  async approveSupplyById(params: { id: number; lang: string }): Promise<any> {
    const supplyList = (this.state as DemandSupplyModule).PaginatedApproveAllocation;
    const res = await API.approveAllocationById(params.id, params.lang);
    const index = supplyList.results.findIndex(x => x.id == params.id);
    if (index >= 0 && supplyList.results[index]) {
      supplyList.results.splice(index, 1, res);
    }
    return { PaginatedApproveAllocation: supplyList as PaginatedSupply };
  }

  @MutationAction
  async rejectSupplyById(params: { id: number; message: string; lang: string }): Promise<any> {
    const supplyList = (this.state as DemandSupplyModule).PaginatedApproveAllocation;
    const res = await API.rejectAllocationById(params.id, params.message, params.lang);
    const index = supplyList.results.findIndex(x => x.id == params.id);
    if (index >= 0 && supplyList.results[index]) {
      supplyList.results.splice(index, 1, res);
    }
    return { PaginatedApproveAllocation: supplyList as PaginatedSupply };
  }

  @MutationAction
  async getAllSupplyCluster(
    params: {
      lang: string;
      page?: number;
      fiscalYearId?: number;
      searchCode?: string;
      type?: any;
    } = {
      lang: "en",
      page: 1
    }
  ) {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.type) {
        query += "&type=" + String(params.type);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
      if (params.lang) {
        query += "&lang=" + params.lang;
      }
    }
    let supplyList = await API.getAllSupplyCluster(query);
    return { SupplyCluster: supplyList as ClusterPaginated };
  }

  @MutationAction
  async getSupplyByClusterId(params: { clusterId: number; lang?: string }): Promise<any> {
    let query = ``;
    if (params.lang) {
      query += `?lang=${params.lang}`;
    }
    let supplyList = await API.getDemandSupplyByClusterId(params.clusterId, query);
    return { Supplies: supplyList as Supply[] };
  }

  @MutationAction
  async getSupplyById(id: number): Promise<any> {
    let supply = await API.getSupplyById(id);
    return { Supply: supply as Supply };
  }

  @Action
  async postSupply(supply: any): Promise<any> {
    return await API.postSupply(supply);
    // return { SeedTypeList: seedTypeList as SeedType };
  }

  @Action
  async deleteSupply(id: number): Promise<any> {
    return await API.deleteSupply(id);
  }

  @Action
  async confirmSupplies(ids: number[]) {
    await API.confirmSupplies(ids);
  }

  @MutationAction
  async getPreferences(): Promise<any> {
    let preferences = await API.getPreferences();
    return { Preferences: preferences as Preferences[] };
  }

  @MutationAction
  async getMyAllocation(
    params: {
      page?: number;
    } = {
      page: 1
    }
  ): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
    }
    let res = await API.getMyAllocation(query);
    return { MyAllocation: res };
  }

  @MutationAction
  async getVerifyAllocation(
    params: {
      lang: string;
      page?: number;
      fiscalYearId?: number;
      cropTypeId?: number;
      cropVarietyId?: number;
      searchCode?: string;
      type?: any;
    } = {
      lang: "en",
      page: 1
    }
  ): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&demand_id__subsidy_batch__fiscal_year=" + String(params.fiscalYearId);
      }
      if (params.cropVarietyId) {
        query += "&demand_id__cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.cropTypeId) {
        query += "&demand_id__cropvarietyid__croptypeid=" + String(params.cropTypeId);
      }
      if (params.type) {
        query += "&type=" + String(params.type);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
      if (params.lang) {
        query += "&lang=" + params.lang;
      }
    }
    let res = await API.getVerifyAllocation(query);
    return { MyAllocation: res };
  }

  @Action
  async patchMyAllocationValidity(params: { id: any; validity: any }): Promise<any> {
    let res = await API.patchMyAllocationVerify(params.validity, params.id);
    return res;
  }

  @Action
  async patchMyAllocation(params: { id: any; doc: any }): Promise<any> {
    let res = await API.patchMyAllocation(params.doc, params.id);
    return res;
  }

  @Action
  async postPreference(preferences: Preferences): Promise<any> {
    return await API.postPreference(preferences);
    // return { SeedTypeList: seedTypeList as SeedType };
  }

  @Action
  async deletePreference(id: number): Promise<any> {
    return await API.deletePreference(id);
  }

  @Action
  async deleteSubsidy(id: number): Promise<any> {
    return await API.deleteSubsidy(id);
  }

  @MutationAction
  async getFyWiseSubsidyCrops(params: { lang: string } = { lang: "en" }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.lang) {
        query += `&lang=${params.lang}`;
      }
    }
    let subsidy = await API.getFyWiseSubsidyCrops(query);
    return { FyWiseSubsidyCrops: subsidy as SubsidyForm[] };
  }

  @MutationAction
  async getFyWiseSubsidy(params: { lang: string } = { lang: "en" }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.lang) {
        query += `&lang=${params.lang}`;
      }
    }
    let subsidy = await API.getFyWiseSubsidy(query);
    return { FyWiseSubsidy: subsidy as SubsidyForm[] };
  }

  @MutationAction
  async getSubsidyCrops(params: { lang: string } = { lang: "en" }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.lang) {
        query += `&lang=${params.lang}`;
      }
    }
    let subsidy = await API.getSubsidyCrops(query);
    return { SubsidyCrops: subsidy as SubsidyForm[] };
  }

  @MutationAction
  async getSubsidy(params: { lang: string } = { lang: "en" }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.lang) {
        query += `&lang=${params.lang}`;
      }
    }
    let subsidy = await API.getSubsidy(query);
    return { Subsidy: subsidy as SubsidyForm[] };
  }

  @MutationAction
  async getCurrentSubsidyBatch(params: { cropId: number }): Promise<any> {
    let query = "";
    let subsidyBatch = await API.getSubsidyBatch(params.cropId, query);
    return { CurrentSubsidyBatch: subsidyBatch as SubsidyBatch };
  }

  @MutationAction
  async submitSubsidyBatch(params: { id: number }): Promise<any> {
    let subsidyBatch = await API.submitSubsidyBatch(params.id);
    return { CurrentSubsidyBatch: subsidyBatch as SubsidyBatch };
  }

  @MutationAction
  async getSubsidyDocument(params: { subsidyBatchId: number }): Promise<any> {
    let query = "";
    let subsidyDocument = await API.getSubsidyDocument(params.subsidyBatchId, query);
    return { CurrentSubsidyDocument: subsidyDocument as SubsidyDocument };
  }

  @Action
  async postSubsidy(subsidy: SubsidyForm): Promise<any> {
    return await API.postSubsidy(subsidy);
    // return { SeedTypeList: seedTypeList as SeedType };
  }

  @MutationAction
  async updateSubsidyDocuments(params: { subsidyDocument: any; id: number }): Promise<any> {
    let subsidyDocument = await API.updateSubsidyDocuments(params.id, params.subsidyDocument);
    return { CurrentSubsidyDocument: subsidyDocument as SubsidyDocument };
    // return { SeedTypeList: seedTypeList as SeedType };
  }

  @MutationAction
  async patchSubsidyDocument(params: { subsidyDocument: any; id: number }): Promise<any> {
    // console.log(params)
    let subsidyDocument = await API.patchSubsidyDocument(params.id, params.subsidyDocument);
    return { CurrentSubsidyDocument: subsidyDocument as SubsidyDocument };
  }

  @Action
  async removeSubsidyDocument(params: { subsidyDocument: any; id: number }) {
    console.log("action -> ", params.subsidyDocument, params.id);
    return await API.removeSubsidyDocument(params.id, params.subsidyDocument);
  }

  @MutationAction
  async getSourceSeedSubsidySchedule(): Promise<any> {
    const query = "?schedule_type=source_seed";
    let subsidySchedule = await API.getSubsidySchedule(query);
    return { SourceSeedSubsidySchedule: subsidySchedule as SubsidySchedule };
  }

  @MutationAction
  async getImprovedSeedSubsidySchedule(): Promise<any> {
    const query = "?schedule_type=improved_seed";
    let subsidySchedule = await API.getSubsidySchedule(query);
    return { ImprovedSeedSubsidySchedule: subsidySchedule as SubsidySchedule };
  }

  @MutationAction
  async getSubsidySchedule(): Promise<any> {
    let subsidySchedule = await API.getSubsidySchedule();
    return { SubsidySchedule: subsidySchedule as SubsidySchedule };
  }

  @MutationAction
  async setSubsidySchedule(subsidySchedule: SubsidySchedule): Promise<any> {
    return { SubsidySchedule: subsidySchedule as SubsidySchedule };
  }

  @MutationAction
  async updateSubsidySchedule(subsidySchedule: SubsidySchedule): Promise<any> {
    subsidySchedule = await API.updateSubsidySchedule(subsidySchedule);
    return { SubsidySchedule: subsidySchedule as SubsidySchedule };
  }

  @MutationAction
  async getSuppliers(params: {
    cropVarietyId: number;
    seedTypeId: number;
    fiscalYearId?: number;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.cropVarietyId) {
        query += `&cropvarietyid=${params.cropVarietyId}`;
      }
      if (params.seedTypeId) {
        query += `&seedtypeid=${params.seedTypeId}`;
      }
      if (params.fiscalYearId) {
        query += `&fiscalyearid=${params.fiscalYearId}`;
      }
    }
    let supplyList = await API.getSupplier(query);
    return { Suppliers: supplyList as Supplier[] };
  }

  @MutationAction
  async getCurrentMarketStat(): Promise<any> {
    let marketStat = await API.getCurrentMarketStat();
    return { MarketStat: marketStat as MarketStat };
  }

  @MutationAction
  async getSupplierStat(): Promise<any> {
    let supplierStat = await API.getSupplierStat();
    return { SupplierStat: supplierStat as SupplierStat };
  }

  @MutationAction
  async getSupplierInfo(params?: {
    page?: number;
    cropId?: number;
    seedTypeId?: number;
    cropVarietyId?: number;
    districtId?: number;
    supplierName?: string;
  }): Promise<any> {
    let query = "";
    if (params) {
      query = "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.cropId) {
        query += "&croptypeid=" + String(params.cropId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.districtId) {
        query += "&districtid=" + String(params.districtId);
      }
      if (params.supplierName) {
        query += "&companyname=" + String(params.supplierName);
      }
    }
    let supplierInfo = await API.getSupplierInfo(query);
    return { SupplierInfo: supplierInfo as SupplierInfoPaginated };
  }

  @MutationAction
  async getSupplierBySlug(slug: string): Promise<any> {
    let supplierDetail = await API.getSupplierBySlug(slug);
    return { SupplierDetail: supplierDetail as SupplierDetail };
  }

  @MutationAction
  async getNotices(): Promise<any> {
    let noticeList = await API.getNotices();
    return { Notices: noticeList as NoticeList };
  }

  @Action
  async distributionsSold(distributions: Distribution[]): Promise<any> {
    return await API.distributionsSold(distributions);
  }

  @Action
  async saveDistributionSoldBill(distributions: any): Promise<any> {
    return await API.saveDistributionSoldBill(distributions);
  }

  @Action
  async getBalanceSheetReport(params: {
    fiscalYearId: number;
    cropTypeId: number;
    seedTypeId: number;
    role: string;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropTypeId) {
        query += "&croptypeid=" + String(params.cropTypeId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
    }
    return await API.getBalanceSheetReport(query);
  }

  @Action
  async getSubsidyReport(data: SubsidyReport): Promise<SubsidyReport> {
    return await API.getSubsidyReport(data);
  }

  @Action
  async getDemandReport(data: SubsidyReport): Promise<SubsidyReport> {
    return await API.getDemandReport(data);
  }

  @Action
  async getSupplyReport(data: SubsidyReport): Promise<SubsidyReport> {
    return await API.getSupplyReport(data);
  }

  @Action
  async getMunicipalityWiseAllocationReport(data: {
    fiscal_year: number;
    crop_type: number;
    seed_type: number;
    demand_company_user: number;
  }) {
    return await API.getMunicipalityWiseAllocationReport(data);
  }

  @MutationAction
  async unsoldAllocationReport(params: {
    page?: number;
    fiscalyearid?: number;
    provinceid?: number;
    districtid?: number;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalyearid) {
        query += "&fiscalyearid=" + String(params.fiscalyearid);
      }
      if (params.provinceid) {
        query += "&provinceid=" + String(params.provinceid);
      }
      if (params.districtid) {
        query += "&districtid=" + String(params.districtid);
      }
    }
    let unsoldDetails = await API.unsoldAllocationReport(query);
    console.log(unsoldDetails);
    return { UnsoldDetailsPaginated: unsoldDetails as PaginatedDistribution };
  }

  @MutationAction
  async unsoldAllocationReportDetail(params: {
    page?: number;
    demandcompanyid: any;
    fiscalyearid?: any;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.demandcompanyid) {
        query += "&demandcompanyid=" + String(params.demandcompanyid);
      }
      if (params.fiscalyearid) {
        query += "&fiscalyearid=" + String(params.fiscalyearid);
      }
      let unsoldViewDetail = await API.unsoldAllocationReportDetail(query);
      return { UnsoldViewDetail: unsoldViewDetail as PaginatedUnsoldViewDetail };
    }
  }

  @MutationAction
  async unsoldAllocationDemandDetail(params: {
    demandcompanyid: any;
    fiscalyearid?: any;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.demandcompanyid) {
        query += "&demandcompanyid=" + String(params.demandcompanyid);
      }
      if (params.fiscalyearid) {
        query += "&fiscalyearid=" + String(params.fiscalyearid);
      }
      let unsoldDemandDetail = await API.unsoldAllocationDemandDetail(query);
      return { UnsoldDemandDetail: unsoldDemandDetail as Distribution };
    }
  }

  @MutationAction
  async getAllPurchases(lang: string = "en"): Promise<any> {
    let query = `?lang=${lang}`;
    let list = await API.getAllPurchases(query);
    return { Purchases: list as Purchase[] };
  }

  @MutationAction
  async getAdminPurchases(params: {
    page?: number;
    fiscalYearId?: number;
    cropId?: number;
    seedTypeId?: number;
    cropVarietyId?: number;
    districtId?: number;
    status?: string;
    searchCompany?: string;
    searchCode?: string;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropId) {
        query += "&croptypeid=" + String(params.cropId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.districtId) {
        query += "&districtid=" + String(params.districtId);
      }
      if (params.status) {
        query += "&status=" + String(params.status);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
      if (params.searchCompany) {
        query += "&companyname=" + String(params.searchCompany);
      }
    }

    let purchaseList = await API.getAdminPurchases(query);
    return { PaginatedPurchase: purchaseList as PaginatedPurchase };
  }

  @MutationAction
  async getAllPurchaseCluster(
    params: {
      lang: string;
      page?: number;
      fiscalYearId?: number;
      searchCode?: string;
      type?: any;
    } = {
      lang: "en",
      page: 1
    }
  ) {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.type) {
        query += "&type=" + String(params.type);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
      if (params.lang) {
        query += "&lang=" + params.lang;
      }
    }
    let list = await API.getAllPurchaseCluster(query);
    return { PurchaseCluster: list as ClusterPaginated };
  }

  @MutationAction
  async getPurchaseByClusterId(params: { clusterId: number; lang?: string }): Promise<any> {
    let query = ``;
    if (params.lang) {
      query += `?lang=${params.lang}`;
    }
    let list = await API.getDemandSupplyByClusterId(params.clusterId, query);
    console.log(list);
    return { Purchases: list as Purchase[] };
  }

  @Action
  async postPurchase(purchase: any): Promise<any> {
    return await API.postPurchase(purchase);
  }

  @Action
  async deletePurchase(id: number): Promise<any> {
    return await API.deletePurchase(id);
  }

  @Action
  async confirmPurchases(ids: number[]) {
    await API.confirmPurchases(ids);
  }

  @MutationAction
  async getAllSales(lang: string = "en"): Promise<any> {
    let query = `?lang=${lang}`;
    let list = await API.getAllSales(query);
    return { Sales: list as Sale[] };
  }

  @MutationAction
  async getAdminSales(params: {
    page?: number;
    fiscalYearId?: number;
    cropId?: number;
    seedTypeId?: number;
    cropVarietyId?: number;
    districtId?: number;
    status?: string;
    searchCompany?: string;
    searchCode?: string;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropId) {
        query += "&croptypeid=" + String(params.cropId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.districtId) {
        query += "&districtid=" + String(params.districtId);
      }
      if (params.status) {
        query += "&status=" + String(params.status);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
      if (params.searchCompany) {
        query += "&companyname=" + String(params.searchCompany);
      }
    }

    let saleList = await API.getAdminSales(query);
    return { PaginatedSale: saleList as PaginatedSale };
  }

  @MutationAction
  async getAllSaleCluster(
    params: {
      lang: string;
      page?: number;
      fiscalYearId?: number;
      searchCode?: string;
      type?: any;
    } = {
      lang: "en",
      page: 1
    }
  ) {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.type) {
        query += "&type=" + String(params.type);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
      if (params.lang) {
        query += "&lang=" + params.lang;
      }
    }
    let list = await API.getAllSaleCluster(query);
    return { SaleCluster: list as ClusterPaginated };
  }

  @MutationAction
  async getSaleByClusterId(params: { clusterId: number; lang?: string }): Promise<any> {
    let query = ``;
    if (params.lang) {
      query += `?lang=${params.lang}`;
    }
    let list = await API.getDemandSupplyByClusterId(params.clusterId, query);
    console.log(list);
    return { Sales: list as Sale[] };
  }

  @Action
  async postSale(sale: any): Promise<any> {
    return await API.postSale(sale);
  }

  @Action
  async deleteSale(id: number): Promise<any> {
    return await API.deleteSale(id);
  }

  @Action
  async confirmSales(ids: number[]) {
    await API.confirmSales(ids);
  }

  @MutationAction
  async getSubisdyNotice(params: { lang: string } = { lang: "en" }) {
    let query = "";
    if (params) {
      query += "?";
      if (params.lang) {
        query += `&lang=${params.lang}`;
      }
    }
    const notice = await API.getSubsidyNotice(query);
    return { SubsidyNotice: notice };
  }

  @Action
  async uploadSourceSeedSaleBill(params: { data: FormData; id: number }) {
    return await API.uploadSourceSeedSaleBill(params.data, params.id);
  }

  @Action
  async approveSalesDetail(params: { data: any; id: number }) {
    return await API.approveSalesDetail(params.id, params.data);
  }

  @Action
  async rejectSalesDetail(params: { data: any; id: number }) {
    return await API.rejectSalesDetail(params.id, params.data);
  }

  @Action
  async updateAdminSupply(supply: any): Promise<any> {
    return await API.updateAdminSupply(supply.id, supply);
  }

  @Action
  async updateAdminDemand(demand: any): Promise<any> {
    return await API.updateAdminDemand(demand.id, demand);
  }
}

export default getModule(DemandSupplyModule);
